import React from "react"
import BasicList from "../../components/BasicList"
import BlogTitle from "../../components/BlogTitle"
import Container from "../../components/Container"
import Hero from "../../components/Hero"
import HeroLead from "../../components/HeroLead"
import HeroTitle from "../../components/HeroTitle"
import Layout from "../../components/Layout"
import SEO from "../../components/SEO/SEO"
import Text from "../../components/Text"
import links from "../../content/links"
import metaImg from "../../images/website-buyer-guide-cover.svg"

export default (): JSX.Element => (
  <Layout>
    <SEO
      title="Nettisivujen ostajan opas"
      description="Tässä artikkelissa käymme läpi, mitä kannattaa ottaa huomioon ostaessa ja suunnitellessa nettisivuja."
      pageImage={metaImg}
      pageUrl={links.blogSites.websiteBuyerGuide}
    />

    <section id="hero">
      <Hero hideContact>
        <HeroTitle>
          <h1>
            <span className="block">Nettisivujen ostajan opas</span>
            <span className="block text-indigo-400">
              – nettisivut yritykselle
            </span>
          </h1>
        </HeroTitle>
        <HeroLead>Kirjoittanut: Essi Kivivuori</HeroLead>
      </Hero>
    </section>

    <section>
      <Container>
        <Text>
          Oletko miettimässä uusien nettisivujen hankkimista tai vanhojen
          sivujen päivittämistä? Tiedätkö mistä aloittaa ja mitä edes ottaa
          huomioon? Sinulla saattaa olla mielessä monia kysymyksiä, etkä
          välttämättä löydä niihin selkeitä vastauksia.
        </Text>
        <Text>
          Tässä blogikirjoituksessa käymme läpi, mitä kannattaa ottaa huomioon
          ostaessa/ suunnitellessa nettisivuja.
        </Text>
        <BlogTitle>Nettisivujen päivittäminen vai uudet nettisivut?</BlogTitle>
        <Text>
          Jos sinulla on jo olemassa olevat nettisivut on hyvä miettiä, että
          kannattaako nettisivut ainoastaan päivittää vai olisiko parempi
          vaihtoehto rakentaa sivut alusta asti uudelleen. Jos nettisivuja
          lähdetään päivittämään, on otettava huomioon miten vanhat sivut on
          rakennettu ja kuinka hyvin niiden päivittäminen on hallittavissa.
        </Text>
        <Text>
          Usein kuitenkin halutaan, että nettisivut rakennetaan uudelle pohjalle
          hyödyntäen olemassa olevien sivujen sisältöä. Mikäli sama sisältö
          tulee uusille sivuille, on hyvä myös huomioida miten teksti on
          suunniteltu. Onko se suunniteltu hakukoneystävälliseksi, miten se on
          jaoteltu ja onko tarvetta lisätä tekstiä.
        </Text>
        <BlogTitle>Nettisivujen hinta</BlogTitle>
        <Text>
          Asiakkaan ensimmäinen kysymys on usein, “kuinka paljon nettisivut
          maksavat”. Nettisivujen hinnoittelu vaihtelee todella paljon. Usein
          suuremmissa yrityksissä hinnat ovat korkeammat. Hinta ei kuitenkaan
          takaa laatua. Voit hyvinkin saada pienemmällä hinnalla laadukkaammat
          nettisivut kuin maltailla maksavista sivuista.
        </Text>
        <Text>
          Nettisivujen tarkkaa hintaa on usein mahdoton sanoa heti ensimmäisellä
          kerralla. Yleensä sinulle annetaan hinta-arvio siitä, mitä sivut
          tulisivat maksamaan. Jos osaat kuvailla tarkkaan sen mitä haluat
          sivuilla olevan ja miten sivuston osiot toimivat, sitä paremmin myyjä
          osaa kertoa paljon tarkemman hinta-arvion sinulle.
        </Text>
        <Text>
          Nykyään nettisivujen hinnat ovat laskeneet huomattavasti siitä, mitä
          ne ovat aikaisemmin olleet. Monia uusia julkaisuteknologioita on
          kehitetty, sekä verkkojulkaisemisesta on tullut entistä helpompaa
          vaatien vähemmän teknistä osaamista.
        </Text>
        <BlogTitle tag="h3">
          Nettisivujen hinnat koostuvat muun muassa seuraavista asioista:
        </BlogTitle>
        <BasicList>
          <li>
            Sisältö: Sisällön suunnittelu, kirjoittaminen ja lopulta muotoilu
          </li>
          <li>
            Ulkoasu: Yritykselle sopivan ulkoasun suunnittelu, sekä sen toteutus
          </li>
          <li>Laajuus: Kuinka paljon on sisältöä, sekä sivujen määrä</li>
          <li>
            Asiakasystävällisyys: Kuinka helppokäyttöinen sivusto on ja miten se
            toimii eri laitteilla
          </li>
          <li>
            Hakukonenäkyvyys, eli tehdäänkö sivustosta hakukoneystävällinen
          </li>
          <li>Ylläpito: Kuka hoitaa ylläpidon ja sivuston päivittämisen</li>
        </BasicList>
        <BlogTitle>Sisällönhallintajärjestelmä</BlogTitle>
        <Text>
          Jos haluat itse suunnitella ja ylläpitää nettisivuja niin yksi hyvä
          vaihtoehto on luoda nettisivut WordPressiä käyttämällä. WordPressin
          käyttö on helppoa, sekä se on ylivoimainen markkinajohtaja. Youtubesta
          löydät tuhansia videoita siitä, miten asentaa ja käyttää kyseistä
          ohjelmistoa. WordPressin avulla voit ylläpitää ja päivittää sivujen
          sisältöä helposti ja nopeasti, etkä tarvitse siihen mitään
          erikoisosaamista.
        </Text>
        <Text>
          WordPressissä on valmiiksi muutamia sivustoteemoja. Nämä teemat
          ilmaisia, mutta voit myös ostaa maksullisia ja kustomoida niistä juuri
          sinun näköiset. Teemojen avulla pystyt rakentamaan sivustoille
          haluamiisi kohtiin tekstikentät, kuvat ja vaikka yhteydenottolomakkeet
          tai painikkeet.
        </Text>
        <BlogTitle>Nettisivujen ulkoasu ja responsiivisuus</BlogTitle>
        <Text>
          Nettisivujen ulkoasu on ensimmäinen asia minkä sinun asiakkaasi tulee
          näkemään, joten panosta siihen jo heti alkumetreillä. Ulkoasun
          tyyleissä on monia eri näkemyksiä ja monia teemoja. Jos tavoitteena on
          omaperäiset, pikkutarkat ja juuri sinulle räätälöidyt sivut, saatat
          joutua maksamaan niistä hieman enemmän. Toinen vaihtoehto on kuitenkin
          käyttää ulkoasuja, joita myydään muutamalla kympillä ellei niitä
          anneta jopa ilmaiseksi. Halvat sivustot eivät kuitenkaan ole
          omaperäiset ja niitä saatettu myydä jo kymmenille.
        </Text>
        <Text>
          Jos nettisivujen ulkoasussa on paljon yksityiskohtia, paljon
          painikkeita ja kaikkea muuta sälää, nettisivut saattavat olla
          asiakkaan näkökulmasta sekavat ja näin ollen saatat menettää
          asiakkaita jo pelkästään ulkoasun takia. Nettisivujen tulee olla
          selkeät siitäkin huolimatta vaikka ne olisi räätälöity juuri sinun
          yrityksellesi. Nettisivut ovat asiakastasi varten – ei sinua.
        </Text>
        <Text>
          Responsiiviset sivut tarkoittavat sitä, että nettisivut skaalautuvat
          automaattisesti käytetyn laitteen näytön mukaan (puhelin, tabletti,
          tietokone). Sinulle tehdään periaatteessa yhdet nettisivut, jotka
          toimivat kaikilla laitteilla. Mobiililaitteissa tekstit ja kuvat ovat
          pienempiä kuin pöytätietokoneilla. Responsiiviset nettisivut saattavat
          nostaa hieman hintaa, mutta responsiivisuus kannattaa. Nykyään
          kuitenkin niin moni ihminen etsii palveluita mobiililaitteilla ja ne
          yleistyvät koko ajan vain enemmän ja enemmän.
        </Text>
        <BlogTitle>Hakukoneoptimointi</BlogTitle>
        <Text>
          Hakukoneoptimointi on niin sanotusti ilmaista mainontaa eli se on
          halvin tapa saada kävijöitä omille sivuillesi ja hakukoneoptimoinnin
          avulla voit päästä hakukoneiden hakutulosten kärkeen.
          Hakukoneoptimointi tarkoittaa sitä, että sivusto tulee näkymään
          hakutuloksissa korkealla valituilla hakusanoilla, eli avainsanoilla.
        </Text>
        <Text>
          Hakukoneoptimointiin vaikuttavat monet asiat, mutta esimerkiksi on
          hyvä aloittaa siitä, että sinulla on tarpeeksi asiaankuuluvaa sisältöä
          nettisivuillasi. Nettisivujesi latausnopeus tulee olla mahdollisimman
          nopea ja nettisivujen on hyvä olla responsiiviset. Sinun on osattava
          käyttää tekstissäsi avainsanoja millä toivot löytyväsi
          hakukonetuloksissa.
        </Text>
        <Text>
          Kun olet tilaamassa nettisivuja on hyvä keskustella niiden tekijän
          kanssa hakukoneoptimoinnista ja minkä hintaista se tulisi olemaan.
          Yleensä henkilöt, jotka tekevät työkseen nettisivuja osaavat
          perusasiat hakukoneoptimoinnista, mutta se on hyvä ottaa alkuvaiheessa
          heti puheeksi.
        </Text>
        <BlogTitle>Domain</BlogTitle>
        <Text>
          Verkkotunnuksella tarkoitetaan internetosoitetta, mitä käytetään
          esimerkiksi nettisivujen tai sähköpostilaatikoiden osoitteena.
          Esimerkiksi .fi-verkkotunnus mielletään luotettavana suomalaisena
          valintana. Tunnuksen pystyy hankkimaan verkkotunnusvälittäjältä tai
          nettisivujen tekijä voi sen sinulle myös hankkia. Viestintävirasto
          veloittaa .fi-tunnuksen rekisteröinnistä 9€ / vuosi.
        </Text>
        <Text>
          Muita päätteitä on myös esimerkiksi .com, .net, .info, .org. Nämä ovat
          kansainvälisiä domaineja, mitä voit rekisteröidä vapaasti.
        </Text>
        <BlogTitle>Muut ominaisuudet ja lisäpalvelut</BlogTitle>
        <Text>
          Usein nettisivuille halutaan lisäominaisuuksia esimerkiksi sosiaalisen
          median integraatioita, kuvagallerioita, yhteydenottolomakkeita tai
          blogeja. Niistä on hyvä keskustella nettisivujen tekijän kanssa ja
          kysyä mahdollisista lisäkustannuksista. Lisäpalvelut voidaan lisätä
          sivuille myöhemmin sivujen julkaisun jälkeen, mutta se saattaa tulla
          siinä vaiheessa hieman kalliimmaksi kuin jos sen olisi teettänyt
          samalla kun nettisivuja alettiin rakentamaan.
        </Text>
        <Text>
          Useissa tapauksissa lisäpalvelut ja ominaisuudet saattavat tuoda
          merkittävää arvoa ja uskottavuutta yrityksellesi. On hyvä idea
          miettiä, miten kilpailijasi nettisivut on rakennettu. Onko heillä
          esimerkiksi blogeja tai kuvagallerioita tuotteistaan? Näin pystyt
          hieman arvioimaan sitä, millä mahdollisilla tavoilla pystyisit
          yltämään kilpailijasi tasolle tai jopa ohittamaan heidät.
        </Text>
        <BlogTitle>Nettisivujen markkinointi</BlogTitle>
        <Text>
          Kun nettisivut ovat valmiit, työ ei ole vielä siinä vaiheessa ohi.
          Pelkät nettisivut eivät aja liikennettä sivustollesi vaan tässä
          vaiheessa sinun on aika alkaa suunnitella miten kävijät löytävät
          sivuillesi. Jos sinulla ei ole alkuvaiheessa vielä
          markkinointistrategiaa niin nyt on sen aika.
        </Text>
        <Text>
          Nettisivuja on hyvä mainostaa etukäteen niin sanotulla “tulossa pian”
          -sivustolla. On myös hyvä tehdä etukäteen sosiaaliseen mediaan
          yrityksen tilit (facebook, twitter, linkedin, instagram), riippuen
          mitä kanavia haluat käyttää nettisivujen mainostamiseen.
        </Text>
        <Text>
          Kun nettisivut ovat julkaistu, kannattaa siitä laittaa postaukset
          sosiaalisen median kanaviin. Tunnusten luomiset, postauksien
          kirjottamiset eivät maksa yrityksellesi mitään ja niiden avulla voit
          saada yrityksellesi tunnettavuutta. Esimerkiksi Facebookissa, pyydä
          sinun ystäväsi tykkäämään yrityksesi sivusta ja jakamaan sitä
          eteenpäin – näin pystyt saavuttamaan heti alkumetreillä potentiaalisia
          asiakkaita.
        </Text>
        <Text>
          Voit myös mainostaa yritystäsi esimerkiksi Googlen hakutuloksissa
          Adwordsin avulla. Adwordsissa voit itse määrittää esimerkiksi
          klikkauskohtaisen hinnan, mitä olet valmis maksamaan yhdestä
          mainoksesi klikkauksesta. Mainostaminen Facebookissa, LinkedInissä,
          Twitterissä ja muissa sosiaalisen median palveluissa voi olla hyvä
          idea, riippuen missä kanavissa juuri sinun yrityksesi asiakkaat
          viettävät aikaa.
        </Text>
        <BlogTitle>Nettisivujen tekijä</BlogTitle>
        <Text>
          Kun kirjoitat Googlen hakukenttään esimerkiksi nettisivut yritykselle,
          tulet löytämään, satoja ellet jopa tuhansia potentiaalisia yrityksiä,
          mitkä tuottavat nettisivuja. Nettisivuja tuottavien yritysten koot
          vaihtelevat yhden hengen toiminimistä isoihin, monen sadan hengen
          ohjelmistoyrityksiin. Koska tekijöitä on paljon, tulet varmasti
          löytämään sen yrityksen kenen kanssa haluat tehdä yhteistyötä ja luoda
          juuri sinun näköiset nettisivut.
        </Text>
        <Text>
          Kun etsit yritystä, joka olisi valmis tekemään sinulle nettisivut on
          tärkeää, että sinulla on jo heti alkumetreillä hyvä fiilis kyseisestä
          yrityksestä. Se että sinusta tuntuu, että sinua kuunnellaan ja sinulle
          vastataan ammattitaitoisesti. Hyvä vuorovaikutus nettisivujen tekijän
          kanssa on tärkeää, sillä sen avulla pystyt saamaan nettisivuista juuri
          sinun näköiset ja sinulle jäisi hyvä mieli kyseisestä yrityksestä joka
          on tuottanut sivusi – jälkeenpäin kaikkien päivitysten tekemiset ja
          muutoksetkin on mukavampi tehdä hyvän yhteisymmärryksen ansiosta.
        </Text>
        <BlogTitle>Vältä yleisimmät virheet</BlogTitle>
        <Text>
          Jokaisella nettisivulla tulisi olla selkeä tavoite. Se voi esimerkiksi
          olla yhteydenottojen hankkiminen, informaation jakaminen tai vaikka
          tuotteiden myynti. Voit tehdä nettisivut itse tai hankkia sen
          nettisivuja tekevältä yritykseltä.
        </Text>
        <BlogTitle tag="h3">
          Nettisivut tulee tehdä asiakasta varten ei sinua
        </BlogTitle>
        <Text>
          Monesti näkee nettisivuja missä puhutaan ammattikieltä, termistöä jota
          asiakas ei välttämättä ymmärrä, eikä hänen tarvitsekaan ymmärtää. Jos
          haluat käyttää ammattikieltä nettisivuilla niin varmista, että olet
          kirjoittanut myös sen mitä kyseinen termi tarkoittaa niin selkeästi
          että asiakkaasi voi ymmärtää sen tuntematta alaasi sen paremmin.
        </Text>
        <Text>
          On varmistettava, että nettisivuilla on tarpeeksi tietoa. Tietojen
          tulee liittyä palveluusi ja se on sijoittettava oikein sivuille.
          Vaikka sivuilla onkin hyvä olla palvelun teknisistä ominaisuuksista
          tietoa varmista silti, että tarjoat samalla asiakkaalle ratkaisun
          hänen ongelmaansa.
        </Text>
        <Text>
          Mieti asia siten, että myyt esimerkiksi auton renkaita. Sinulle tulee
          asiakas joka on juuri ostanut uuden auton ja haluaa ostaa talvirenkaat
          siihen. Nettisivuillasi olisi hyvä olla esimerkiksi tuotetiedot auton
          renkaista, mutta sen lisäksi pystyisit luettelemaan automerkit joihin
          renkaat sopivat. Näin ollen helpotat autonrenkaiden ostajaa ja hänen
          on helpompi tilata renkaat sinulta kun hän tietää niiden sopivan
          autoonsa.
        </Text>
        <BlogTitle tag="h3">
          Onko sinulla liikaa visuaalisia ominaisuuksia
        </BlogTitle>
        <Text>
          Jos nettisivusi on tungettu täyteen visuaalisia ominaisuuksia tulee
          sivuista sekavat ja hankala käyttää. Vaikka olisikin hauskaa laittaa
          paljon näyttäviä efektejä sivuilla, harkitse sitä tarkkaan. Sivuston
          tulee olla selkeä ja helposti luettavissa.
        </Text>
        <Text>
          Fontin valitseminen on tärkeää. Asiakkaasi arvostaa enemmän selkää ja
          helposti luettavaa fonttia kuin hienoilla kiemuroilla olevaa fonttia
          mikä saattaisi näyttää hienolta, mutta on hankala lukea.
        </Text>
        <Text>
          Nettisivuille kannattaa valita yhtenäinen väriteema, mitä käyttää koko
          sivuston teksteihin. Muutaman värin käyttö on hyvä idea, mutta jos
          värejä alkaa olemaan paljon niin saattaa sivuston kokonaisuus olla
          sekava ja asiakkaan mielestä epämiellyttävää lukea ja etsiä tietoa.
        </Text>
        <BlogTitle tag="h3">Sivujen tekstien ja kuvien jaottelu</BlogTitle>
        <Text>
          Nettisivujen teksti tulee olla helposti luettavissa. Esimerkiksi sivun
          reunoille kannattaa jättää tilaa sillä se helpottaa tekstin lukemista.
        </Text>
        <Text>
          Vaikka sinulla olisikin paljon tekstiä, ei sitä tule laittaa yhteen
          pötköön, sillä pitkää tekstiä lukiessa usein mieli herpaantuu ja
          ajatus katkeaa. Onkin hyvä käyttää paljon väliotsikoita ja jaotella
          teksti siten, että se on ilmavaa ja helposti silmäiltävää. On hyvä
          käyttää lyhyitä kappaleita ja listoja.
        </Text>
        <Text>
          Liian vaaleaa tekstiä on hankala lukea, mutta siltikään ei kannata
          käyttää täysin mustaa fonttia. Kannattaa myös miettiä, mihin kohtaan
          kuvat sijoitetaan. Jakavatko kuvat tilan, vai onko esimerkiksi teksti
          kuvan päällä.
        </Text>
        <BlogTitle tag="h3">
          Käyttämäsi kuvat eivät liity asiaan tai ovat huonolaatuisia
        </BlogTitle>
        <Text>
          Nettisivut ovat usein ensimmäinen asiakkaan kosketus yritykseesi,
          siksi on kiinnitettävä huomiota tekstien lisäksi kuviin. Internetistä
          löydät monia tuhansia sivuja joissa on käytetty kuvia mitkä ovat
          todella tummia, tärähtäneitä ja niin sanottua pikselimössöä –
          tällainen kuva ei anna ammattimaista kuvaa sinusta tai yrityksestäsi.
        </Text>
        <Text>
          Laadukas kuva on tärkeä osa nettisivujasi. Sen lisäksi, että kuvan
          tulee olla tarkka, sen on myös liityttävä palveluusi. Jos myyt auton
          renkaita, ei kannata laittaa viereen kuvaa vaikka tietokoneesta, sillä
          se ei liity renkaisiin. Nykypäivänä ammattikuvaajan palkkaaminen ei
          ole mahdottoman kallista ja heidän palveluita on myös hyvä käyttää jos
          siihen on vain mahdollisuus.
        </Text>
        <Text>
          Nettisivuilla kannattaa olla kuvia tuotteista/ palveluista mitä myyt
          ja usein myös asiakkaita kiinnostaa henkilökunta yrityksen takana.
          Joten suositeltavaa olisi edes laittaa nimen viereen kuva henkilöstä –
          se luo ammattimaisen kuvan ja ostajan on helpompi samaistua ja heille
          tulee varmempi olo siitä, että olette oikeasti olemassa ettekä mikään
          huijaussivusto.
        </Text>
        <BlogTitle tag="h3">Sinulla ei ole yhteystietoja</BlogTitle>
        <Text>
          Yhteystietojen on löydyttävä nettisivuilta nopeasti ja helposti.
          Sinulle on ehkä itsestään selvyys, että yhteystiedot löytyvät tietystä
          paikasta, mutta välttämättä asiakkaallesi ei ole. Yhteystiedot on
          sijoitettava helposti löydettäväksi, enintään yhden klikkauksen päähän
          ellei jopa jokaisen sivun alalaidassa (footerissa), muuten asiakkaat
          saattavat nopeasti vaihtaa kilpailijan sivuilla.
        </Text>
        <Text>
          Jos sinulla ei ole yhteystietoja nettisivuillasi niin nyt on jo aika
          laittaa ne sinne! Sinun ei välttämättä ole pakko laittaa
          puhelinnumeroa tai osoitetta (tottakai tämä on parempi jos ne ovat
          siellä näkyvillä), mutta edes sähköpostiosoite minne asiakkaat voivat
          lähettää sinulle sähköpostia kysyäkseen tuotteista tai jos heillä on
          jokin ongelma.
        </Text>
        <Text>
          Puhelinnumeron, sähköpostin sekä osoitteen lisäksi on hyvä mainita
          esimerkiksi y-tunnus, sekä kaikki muut tärkeät tiedot. Nämä antavat
          asiakkaalle mahdollisuuden ottaa sinuun yhteyttä helposti, sekä se
          antaa luotettavan kuvan sinun yrityksestäsi.
        </Text>
        <BlogTitle tag="h3">
          Sivusto ei ole responsiivinen tai ei toimi
        </BlogTitle>
        <Text>
          Nykypäivänä ihmiset etsivät paljon enemmän tietoa älypuhelimillaan
          kuin ennen. Jos nettisivustosi ei skaalaudu älypuhelimen näytön
          mukaan, potentiaaliset asiakkaat saavat huonon kokemuksen sivustosta
          ja päätyvät hyvin nopeasti muiden kilpailijoiden sivustoille.
        </Text>
        <Text>
          Jos sivusto ei toimi kunnolla, kuvat puuttuvat, sivu ei lataudu
          nopeasti, responsiivisuus puuttuu niin asiakkaat kaikkoavat nopeasti.
          Kun nettisivut ovat valmiit ja julkaistu, on hyvä käydä heti
          katsomassa miltä sivut näyttävät tietokoneella, tabletilla ja
          älypuhelimella. Näin pystyt tarkistamaan sen miten sivusto toimii
          kullakin laitteella.
        </Text>
        <BlogTitle tag="h3">
          Hakukoneoptimointiin ei ole kiinnitetty huomiota
        </BlogTitle>
        <Text>
          Vaikka sivusto olisi kuinka hieno ja moderni siitä ei ole hyötyä, jos
          sivustoa ei ole tehty hakukoneystävälliseksi. Jos sivustosi ei ole
          hakukoneoptimoitu, niin saatat löytyä ainoastaan hakutuloksissa, kun
          yrityksesi nimi laitetaan hakukenttään.
        </Text>
        <Text>
          Hakukoneoptimoinnilla nettisivusi voi päästä kärkisijoille
          hakutuloksissa. Sen lisäksi, että sivustosi löydettäisiin, se on
          samalla ilmaista mainontaa. Joten hakukoneoptimointiin kannattaa
          panostaa jo heti alkumetreillä kun nettisivuston tekstejä aletaan
          suunnittelemaan ja kirjoittamaan.
        </Text>
        <BlogTitle tag="h3">Toiminta-kehotukset</BlogTitle>
        <Text>
          Mikäli haluat asiakkaiden ottavan sinuun yhteyttä, kehota siihen.
          Mikäli haluat asiakkaiden ostavan tuotteesi, kehota siihen. Mikäli
          haluat asiakkaiden liittyvän postituslistalle, kehota siihen!
          Kehotuksiin pystyt käyttämään niin kutsuttuja
          “call-to-action”-painikkeita. Niitä painettaessa asiakas ohjautuu
          haluamallesi sivulle minkä olet niihin määrittänyt. Esimerkiksi jos
          sinulla on call-to-action-painike “ota yhteyttä”, voi painike ohjata
          asiakkaan suoraan yhteystiedot sivulle ilman, että asiakkaan täytyisi
          itse lähteä etsimään yhteystiedot-sivua.
        </Text>
      </Container>
    </section>
  </Layout>
)
